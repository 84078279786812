import React, { Component } from 'react'
import { Global } from '@emotion/core'
import { withPrefix } from 'gatsby'

import Layout from '../../../../components/Layout'
import { globalForModal, OfferContainer } from '../../../../styles'
import loadScript from '../../../../utils/scriptLoader'
import addCTAListeners, { requestUpush } from '../../../../utils/addCTAListeners'

class Auto extends Component {

  componentDidMount() {
    requestUpush(['offer-autoins'])
    const target = document.querySelectorAll('#ad_unit_placeholder')
    const config = {childList: true}
    const observer = new MutationObserver((e) => {
      e[0].target.querySelectorAll('.listing').forEach((node) => {
        addCTAListeners(node, ['offer-autoins'])
      })
    })
    target.forEach((node) => {
      observer.observe(node, config)
    })
    loadScript(withPrefix('/js/media-alpha.min.js'))
  }

  render() {
    return (
      <React.Fragment>
        <Global styles={globalForModal} />
        <Layout removeFooter={true}>
          <OfferContainer>
            <h1>YOUR AUTO INSURANCE OPTIONS</h1>
            <div id="ad_unit_placeholder" />
          </OfferContainer>
        </Layout >
      </React.Fragment>
    )
  }
}
export default Auto
